
























import { defineComponent, ref } from '@vue/composition-api'
import { CommonCategories } from '@/enums'
import { Dialog } from '@/components/molecules/Dialog'

export default defineComponent({
  name: 'RatingsDescription',
  components: { CustomDialog: Dialog },
  setup() {
    const isDescriptionVisible = ref(false)
    const categories = ref(Object.keys(CommonCategories))

    return {
      isDescriptionVisible,
      categories,
    }
  },
})
