














import { defineComponent, PropType } from '@vue/composition-api'
import { StarRating } from '@/components/common/Rating/StarRating'

export default defineComponent({
  name: 'RatingInfoShort',
  components: {
    StarRating,
  },
  props: {
    rating: {
      type: Number as PropType<number>,
      required: true,
      default: () => 0,
    },
    ratedTransactions: {
      type: Number as PropType<number>,
      default: () => 0,
    },
    isPartner: {
      type: Boolean as PropType<boolean>,
      default: () => false,
    },
    isInline: {
      type: Boolean as PropType<boolean>,
      default: () => false,
    },
    slotInfo: {
      type: Object as PropType<any>,
      default: () => ({}),
    },
  },
})
