















































import {
  defineComponent,
  watch,
  ref,
  PropType,
  computed,
} from '@vue/composition-api'
import { RatingsDescription } from '@/components/rating/RatingsDescription'
import { RatingOverview } from '@/components/common/Rating/RatingOverview'
import { PageTitle } from '@/components/atoms/PageTitle'

export default defineComponent({
  name: 'PublicRating',
  components: {
    RatingOverview,
    RatingsDescription,
    PageTitle,
  },
  props: {
    rating: {
      type: Object,
      default: () => null,
    },
    isLoading: {
      type: Boolean as PropType<Boolean>,
      default: () => false,
    },
  },
  setup(props) {
    const isPublished = ref(false)

    const publishedStateKey = computed(() =>
      props.rating && props.rating.published ? 'published' : 'unpublished'
    )

    watch(
      () => props.rating,
      (rating) => {
        isPublished.value = rating.published
      }
    )

    return { isPublished, publishedStateKey }
  },
})
