




































































import { computed, defineComponent } from '@vue/composition-api'
import useTradingPartners from '@/components/rating/TradingPartners/composable'
import UserAvatar from '@/components/common/UserAvatar/UserAvatar.vue'
import { RatingPopover } from '@/components/common/Rating/RatingPopover'
import { RatingInfoShort } from '@/components/common/Rating/RatingInfoShort'
import { RatingCategoriesList } from '@/components/common/Rating/RatingCategoriesList'
import { PageTitle } from '@/components/atoms/PageTitle'

export default defineComponent({
  name: 'TradingPartners',
  components: {
    UserAvatar,
    RatingPopover,
    RatingInfoShort,
    RatingCategoriesList,
    PageTitle,
  },
  inject: ['enums'],
  props: {
    partners: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, { root }) {
    const i18n = root.$i18n
    const { getTradingType, handleRoutingAction } = useTradingPartners()

    const tableHeaders = computed(() => [
      {
        text: i18n.t('tradingPartners.table.organisation'),
        value: 'organisation',
      },
      {
        text: i18n.t('tradingPartners.table.paperGrade'),
        value: 'paperGrade',
        width: 140,
      },
      { text: i18n.t('tradingPartners.table.type'), value: 'type' },
      { text: i18n.t('tradingPartners.table.amount'), value: 'amount' },
      { text: i18n.t('tradingPartners.table.ratings'), value: 'ratings' },
      {
        text: i18n.t('tradingPartners.table.transactions'),
        value: 'transactions',
      },
    ])

    const tableItems = computed(() => props.partners || [])

    return { getTradingType, handleRoutingAction, tableHeaders, tableItems }
  },
})
