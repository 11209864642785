import { toRefs, reactive, computed, SetupContext } from '@vue/composition-api'

export default function useRatings(context: SetupContext) {
  const state = reactive({
    tradingPartners: computed(
      () => context.root.$store.getters['rating/tradingPartners']
    ),
    ownRating: computed(() => context.root.$store.getters['rating/ownRating']),
    isOwnRatingLoading: computed(
      () => context.root.$store.getters['rating/isOwnRatingLoading']
    ),
    isTradingPartnersLoading: computed(
      () => context.root.$store.getters['rating/isTradingPartnersLoading']
    ),
    organisationId: computed(
      () => context.root.$store.getters['auth/getOrganisationId']
    ),
  })

  const fetchTradingPartners = async () => {
    await context.root.$store.dispatch(
      'rating/fetchTradingPartners',
      state.organisationId
    )
  }

  const fetchOwnRating = async () => {
    await context.root.$store.dispatch(
      'rating/fetchOwnRating',
      state.organisationId
    )
  }

  const onPublishRating = async (published: boolean) => {
    await context.root.$store.dispatch('rating/onPublishRating', {
      id: state.organisationId,
      body: { published },
    })
  }

  return {
    fetchTradingPartners,
    fetchOwnRating,
    onPublishRating,
    ...toRefs(state),
  }
}
