



























import { defineComponent, PropType } from '@vue/composition-api'
import { StarRating } from '@/components/common/Rating/StarRating'
import { RatingCategoriesList } from '@/components/common/Rating/RatingCategoriesList'
import { RatedCategory } from '@/interfaces'

export default defineComponent({
  name: 'RatingOverview',
  components: {
    StarRating,
    RatingCategoriesList,
  },
  props: {
    rating: {
      type: Array as PropType<RatedCategory[]>,
      default: () => [],
    },
    avgRating: {
      type: Number as PropType<number>,
      default: () => 0,
    },
    ratedTransactions: {
      type: Number as PropType<number>,
      default: () => 0,
    },
    isPartner: {
      type: Boolean as PropType<boolean>,
      default: () => false,
    },
  },
})
