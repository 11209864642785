














import { defineComponent, SetupContext, onMounted } from '@vue/composition-api'
import { TradingPartners } from '@/components/rating/TradingPartners'
import { OwnRating } from '@/components/rating/OwnRating'
import useRatings from '@/components/rating/RatingsPage/composable'

export default defineComponent({
  name: 'RatingsPage',
  components: {
    TradingPartners,
    OwnRating,
  },
  setup(_, context: SetupContext) {
    const {
      fetchTradingPartners,
      fetchOwnRating,
      tradingPartners,
      ownRating,
      isOwnRatingLoading,
      isTradingPartnersLoading,
      onPublishRating,
    } = useRatings(context)

    onMounted(async () => {
      await Promise.all([fetchOwnRating(), fetchTradingPartners()])
    })

    return {
      tradingPartners,
      ownRating,
      isOwnRatingLoading,
      isTradingPartnersLoading,
      onPublishRating,
    }
  },
})
