var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"section"},[_c('page-title',{attrs:{"title":_vm.$t('tradingPartners.title')}}),_c('v-data-table',{staticClass:"bigger-text",attrs:{"loading":_vm.isLoading,"items":_vm.tableItems,"headers":_vm.tableHeaders,"disable-pagination":"","disable-sort":"","disable-filtering":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.organisation",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"meta"},[_c('user-avatar',{staticClass:"avatar",attrs:{"size":_vm.enums.AvatarSize.Default,"user":item}}),_c('span',[_vm._v(_vm._s(item.organisation.name))])],1)]}},{key:"item.paperGrade",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.merged.materialTypes || []).join(', '))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTradingType(item))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")((item.merged.unitAmount || 0)))+_vm._s(_vm.enums.UnitType.Ton)+" ")]}},{key:"item.ratings",fn:function(ref){
var item = ref.item;
return [_c('rating-popover',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('rating-info-short',{attrs:{"slot-info":{ on: on, attrs: attrs },"rated-transactions":item.merged.ratedTransactions,"rating":item.merged.avgRating}})]}}],null,true)},[_c('rating-categories-list',{attrs:{"ratings":item.merged.rating,"disabled":""}})],1)]}},{key:"item.transactions",fn:function(ref){
var item = ref.item;
return [_c('ul',{staticClass:"action-btn"},[_c('li',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.handleRoutingAction(item.organisation.id)}}},[_vm._v(" "+_vm._s(_vm.$t('tradingPartners.actionButtons.show'))+" ")])],1),_c('li',[_c('v-btn',{staticClass:"rate-btn",attrs:{"text":""},on:{"click":function($event){return _vm.handleRoutingAction(item.organisation.id, 'true')}}},[_vm._v(" "+_vm._s(_vm.$t('tradingPartners.actionButtons.rate'))+" ")])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }