import { getCurrentInstance } from '@vue/composition-api'
import { IRelationPartner } from '@/interfaces'
import { TradingStatus } from '@/enums'
import { i18n } from '@/i18n'

export default function useTradingPartners() {
  const vm = getCurrentInstance()?.proxy

  const getTradingType = (partner: IRelationPartner) => {
    const types: string[] = Object.keys(partner).reduce(
      (acc: string[], key: string) => {
        if ((Object.values(TradingStatus) as string[]).includes(key)) {
          acc.push(i18n.t(`tradingPartners.type.${key}`) as string)
        }

        return acc
      },
      []
    )

    return types.join(', ')
  }

  const handleRoutingAction = async (id: string, notRated?: string) => {
    await vm?.$router.push({
      name: 'transactions',
      query: {
        partnersOrganisationsId: [id],
        ...(notRated && { notRated }),
      },
    })
  }

  return { getTradingType, handleRoutingAction }
}
